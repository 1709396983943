<template>
    <div>
        <h2 class="mb-5">Заявки на постановку в очередь</h2>
        <v-data-table
            :headers="requestHeaders"
            :items="requestList"
            :items-per-page="15"
            :loading="isLoading"
            @click:row="handleClick"
            :item-class="itemRowBackground"
            class="elevation-1 request_list">
            <template v-slot:item.decision="{ item }">
                {{ decision[item.decision] }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    mounted() {
        this.getData();
    },

    data: () => ({
        decision: ['Нет решения', 'Положительно', 'Отрицательно'],
        requestHeaders: [],
        requestList: [],
        isLoading: true,
    }),

    methods: {
        handleClick(item) {
            this.$router.push({ path: '/parent/queue-request/'+item.id });
        },
        itemRowBackground: function (item) {
            let rowCLass = '';
            if(item.decision == '1') {
                rowCLass = 'light-green lighten-4';
            } else if(item.decision == '2') {
                rowCLass = 'red lighten-5';
            }
            return rowCLass;
        }, 
        async getData() {
            this.isLoading = true;
            await this.$http.get('/request/all').then((response) => {
                this.requestHeaders = response.data.data.parentRequestList.headers;
                this.requestList = response.data.data.parentRequestList.list;
            }).catch(() => {});
            this.isLoading = false;
        }
    }
}
</script>

<style scoped>

</style>